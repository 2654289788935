import { graphql } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill"

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from "../components/default-hero"

import "../css/ref-library.css"

class ResourceCenterTemplate extends Component {
  render() {
    const page = this.props.data.wpPage
    const { seo } = page
    const groupedResources = this.props.data.allWpResource.nodes.reduce(
      (acc, resource) => {
        resource.resourceCategories.nodes.forEach(it => {
          const category = it.name
          if (!acc[category]) {
            acc[category] = []
          }
          acc[category].push(resource)
        })
        return acc
      },
      {}
    )

    const resourcesArray = Object.entries(groupedResources).map(([category, resources]) => ({
      category,
      resources
    }));

    const insuranceResources = resourcesArray.find(it => it.category === "Insurance Resources");
    const otherResources = resourcesArray.filter(it => it.category !== "Insurance Resources");
    const sortedResources = [insuranceResources, ...otherResources];


    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <DefaultHero title={page.title} backgroundColor="#21c6be" className='ref-library-hero' />

        <div className="container baseContainerWrapper">
          <div className="baseContainer">
            {sortedResources.map(
              ({category, resources}, index) => (
                <div key={category}>
                  <div className="maincopy resources-heading">
                    <h2>{category}</h2>
                  </div>
                  <div className="ref-library-grid">
                    {resources.map((resource, index) => (
                      <div key={index} className="ref-library-item">
                        <a href={resource.link}>
                          <img
                            src={
                              resource.featuredImage.node.localFile.publicURL
                            }
                            alt={resource.featuredImage.node.altText}
                          />
                        </a>
                        <div className="ref-library-item-content">
                          <div>
                            <h3>
                              <a href={resource.link}>{resource.title}</a>
                            </h3>
                            {resource.cptResource.type && (
                              <div>{resource.cptResource.type}</div>
                            )}
                          </div>
                          {resource.cptResource.file && (
                            <a
                              href={
                                resource.cptResource.file.localFile.publicURL
                              }
                              className="ref-library-download"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>PDF</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                              >
                                <path d="M16 480H0V448H16 368h16v32H368 16zM203.3 379.3L192 390.6l-11.3-11.3-128-128L41.4 240 64 217.4l11.3 11.3L176 329.4V224 48 32h32V48 224 329.4L308.7 228.7 320 217.4 342.6 240l-11.3 11.3-128 128z" />
                              </svg>
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {index !== Object.entries(groupedResources).length - 1 && (
                    <div className="ref-library-divider">
                      {Array(50)
                        .fill()
                        .map((_, index) => (
                          <span key={index} className={index > 25 && "lg-only"}>
                            •
                          </span>
                        ))}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </Layout>
    )
  }
}
export default ResourceCenterTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
    }

    allWpResource(filter: { status: { eq: "publish" } }, limit: 500) {
      nodes {
        id
        title
        link
        resourceCategories {
          nodes {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        cptResource {
          file {
            localFile {
              publicURL
            }
          }
          type
        }
      }
    }
  }
`
